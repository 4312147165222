<template>
    <component
        :is="component"
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :nudge-top="25"
        
        max-width="390px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
           
        <v-text-field
            v-model="formatDate"
            v-bind="{...attrs, ...$attrs}"
            v-on="{...on, ...$listeners }"
            readonly
        ></v-text-field>
        </template>
        <div class="  white--text text--darken-1 pa-3" v-if="$attrs.label && !breakpoint.mdAndUp">
          {{$attrs.label}}
        </div>
        <v-date-picker
        v-model="date"
        @input="menu1 = false"
        no-title
        ></v-date-picker>
    </component>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        value: Date
    },
    data: () => ({
      menu1: false,
    }),

    computed: {
        formatDate () {
            return moment(this.value).locale('en').format('YYYY/MM/DD')
        },
        date :{
            get () {
                
                return moment(this.value).locale('en').format('YYYY-MM-DD')
            },
            set ( value ) {
                 
                this.$emit('input', new Date(...value.split('-').map((n, i) => i == 1 ? Number(n)-1 : Number(n) )))
            }
        },
       component () {
        return this.breakpoint.mdAndUp ? () => import('vuetify/lib/components/VMenu') : () => import('vuetify/lib/components/VDialog')
      }
    },

  }
</script>

<style>

</style>